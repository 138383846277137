/* css for decidim_survey_results */
.survey-results-wrapper {
  .alert-survey {
    background-color: lighten(#9595c7, 20%);
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 32px;
    border-left: 4px solid #9595c7;
    margin-bottom: 28px;
  }

  .section-heading {
    margin: 0;
    padding: 24px 0 20px 0;
    font-size: 20px;
    font-weight: bold;

    &::before {
      margin-right: 30px;
    }
  }

  .section-participants {
    display: flex;
    align-items: baseline;
    gap: 12px;
    margin-bottom: 30px;

    p {
      font-size: 14px;
    }

    #users-icon {
      fill: var(--primary);
    }
  }

  .question-box {
    background-color: #fff;
    padding: 50px;
    border: 1px solid #e9e9e9;
    margin-bottom: 100px;

    .question-number {
      margin-bottom: 5px;
      font-size: 14px;
      text-transform: uppercase;
      display: flex;
      align-items: baseline;
  
      img {
        object-fit: contain;
        margin-right: 8px;
      }

      span {
        font-weight: bold;
      }
    }

    .question-title {
      font-size: 20px;
    }

    .big-title {
      text-transform: uppercase;
      font-weight: bold;
    }

    .description {
      font-size: 14px;
    }

    .big-title {
      margin-bottom: 8px;
    }

    table {
      margin-bottom: 70px;

      thead,
      tr,
      tbody {
        background: #fff;
        border: none;
        border-bottom: 1px solid #f2f2f2;
      }

      th {
        font-weight: normal;
        font-size: 14px;

        &:not(:first-child) {
          text-align: center;
        }
      }

      td:not(:first-child) {
        text-align: center;
      }
    }

    &.pie-chart-wrapper {
      .answers-results {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  .answers-chart {
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
  }

  canvas {
    width: 200px;
  }

  .pie-chart {
    width: 300px !important;
    height: 300px !important;
  }
}

// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.

@import "variables";

//GENERAL

a {
    line-height: inherit;
    color: $par-medium-green;
    text-decoration: none;
    font-weight: 520;
    cursor: pointer;
}

.cookie-warning a{
    color: $par-medium-green;
	font-weight: 600;
}

.cookie-warning {
    background: $par-white;
    padding: .5em 1em;
    text-align: center;
}

.main-nav__link a {
    display: block;
    padding: 1em 2em;
    color: rgba(255, 255, 255, 1);
}

.main-nav__link a:hover {
    display: block;
    padding: 1em 2em;
    color: rgba(255, 255, 255, 1);
	  text-decoration: underline;
}

.section-heading::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: .8em;
    background-color: $par-medium-green;
    margin-right: .5rem;
    margin-bottom: -.1rem;
}

#profile-tabs.tabs .tabs-title a:focus, #profile-tabs.tabs .tabs-title a[aria-selected="true"] {
    background: transparent;
	  color: $par-light-blue;
    font-weight: 600;
    border-bottom: 3px solid $par-medium-green;
}

title.is-active > [role="tab"], .side-panel__tabs .tabs-title.is-active > a {
    color: #599aa6;
    box-shadow: inset 4px 0 0 $par-medium-green;
}

.callout.success, .callout.secondary{
    border-color: #e8e8e8;
    border-left: 7px solid var(--success);
    border-top: 3px solid var(--success);
    border-bottom: 3px solid var(--success);
    border-right: 3px solid var(--success);
}

.callout.alert {
    border-color: #e8e8e8;
    border-left-color: rgb(232, 232, 232);
    border-left: 8px solid var(--alert);
	  border-top: 3px solid var(--alert);
    border-bottom: 3px solid var(--alert);
    border-right: 3px solid var(--alert);
}

.section-heading::before {
	margin-top: 0.3em !important;
}

.logo-wrapper img {
    max-height: 65px;
}

.topbar__user__login a:hover{
	color: $par-white;
	text-decoration: underline;
}

.title-bar {
  padding: 1rem 0.5rem;
  background: $par-blue;
  color: #fff;
}

.help-text-big{
  font-size: 1.1em !important;
  font-style: italic;
}


//NAVBAR

.navbar {
	background: $par-light-blue;
}

.topbar__search{
  position: relative;
  max-width: 220px; // Safari fix

  input{
    color: $par-black;
    border-color: transparent;
    box-shadow: none;
    background: $par-white;
    margin-top: 8px;
    display: inline-block;
    border-radius: 4px 0 0 4px;
    transition: .3s all;
    height: 2.5rem; //matches input group button height

    &:focus{
      color: $par-black;
      box-shadow: none;
      background: $par-white;
	    margin-top: 8px;
      outline-color: var(--highlight-alternative);
    }
  }

  .input-group-button{
    position: relative;
    height: 1px; // Fix for Safari and Firefox
    vertical-align: top;
  }

  // Fix for Safari and Firefox
  .button-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  button{
    border-radius: 0 4 4 0;
    width: 40px;
  }

  form:first-child{
    display: inline-flex;
  }

  .input-group{
    margin-bottom: 0;
  }

  &:not(:focus-within){
    button{
      background-color: $par-medium-green;
      color: rgba(white, .5);
    }
  }

  &:focus-within{
    input{
      background-color: light-gray;
      color: $par-black;
      border: 0;

      &::placeholder{
        color: dark-medium-gray;
      }
    }
  }

  svg{
    color: $par-white;
    position: absolute;
    top: 0.8rem;
    left: 0.6rem;
    transform: none;

    opacity: 1;
  }

}

.main-nav__link--active a {
    box-shadow: inset 0 4px 0 0 $par-white;
}

.m-none, .m-none::placeholder, .input-search, .input-search::placeholder, .input-group-field, .input-group-field::placeholder {
    color: $par-dark-grey;
	opacity: 1;
}

//BUTTON

.button{
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    font-family: inherit;
    padding: 0.85em 1em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: $par-medium-green;
    color: #fff;
}

.button:hover, .button:focus, .button.secondary:hover, .button.secondary:focus {
    background-color: $par-medium-green;
    -webkit-filter: brightness(130%);
    filter: brightness(130%);
}

.button.hollow {
    border: 1px solid $par-medium-green;
    color: $par-medium-green;
    background-color: #fff;
}

.button.hollow:hover, .button.hollow:focus{
    border: 1px solid $par-medium-green;
	background-color: $par-medium-green;
	color: #fff;
}

.button.secondary {
    background-color: $par-medium-green;
    color: $par-white;
}

.button.secondary.hollow {
    background-color: transparent;
    color: $par-medium-green;;
    border-color: $par-medium-green;;
}

.button.secondary.hollow:hover, .button.secondary.hollow:focus{
    border: 1px solid $par-medium-green;
    color: $par-medium-green;
	  background-color: $par-medium-green;
	  color: #fff;
}

.home-bullet__icon svg {
    fill: $par-medium-green;
}

.subhero-cta-new {
   margin-top: 2rem;
   display: inline-block !important;
   text-transform: uppercase;

   &:hover,
   &:focus{
     text-decoration: none !important;
     color: white !important;
   }
}

//CARDS

$card-bg: $par-white !default;
$card-secondary-bg: $light-gray-dark;
$card-border: $border;
$card-border-radius: $global-radius;
$card-border-top-width: 8px !default;
$card-shadow: 0 2px 7px rgba(black, .1);
$card-id: $medium-gray;

$card-padding-small: 1rem;
$card-padding: 1.5rem;
$card-margin-bottom: map-get($grid-column-gutter, medium);

$card-footer-bg: $light-gray-dark;

$card-proposal: var(--proposals);
$card-action: var(--actions);
$card-debate: var(--debates);
$card-meeting: var(--meetings);
$card-spaces: var(--meetings);

$datetime-bg: var(--primary);

.card__link {
	color: $par-medium-green;
}

.card__link:hover, .card__link:focus {
	color: $par-medium-green;
    -webkit-filter: brightness(120%);
    filter: brightness(120%);
}

.card-data__item__number{
  display: block;
    height: 36px;
}

.card__image{
  width: 100%;
  max-height: 10rem;
  min-height: 5rem;
  object-fit: cover;

  &.card__image--smaller{
    max-height: 5rem;
  }

  &.card__image--larger{
    max-height: 20rem;
    min-height: 10rem;
  }
}

.card__support__number{
  line-height: 1;
  font-weight: 600;
  font-size: rem-calc(20);
  color: $body-font-color;
}

//HELPER CONTAINER

.floating-helper-container{
	display: none;
}

//HERO

.hero::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
	background-color: transparent;
}

.subhero {
    padding: 2rem 0;
    text-align: center;
}

.hero-heading {
    margin-bottom: 1.5rem;
    line-height: 1;
    text-shadow: 0 0 6px rgba(0, 0, 0, 1);
}

// PROCESS HEADER

.process-header__main::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	background-color: transparent;
}

//FOOTER

.main-footer {
	background: $par-blue;
    position: relative;
    padding: 1rem 0;
    min-height: 76px;
}

.footer-nav li {
    display: inline-block;
    padding: 0 1rem;
}

.mini-footer {
    padding: 1rem 0;
	background: $par-light-blue;
}

.mini-footer a, .main-footer a {
	color: $par-white;
}

.decidim-logo > a:nth-child(1) > span:nth-child(2){
	font-size: 0.90rem;
}

.footer-logos{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
}

a img + .external-link-indicator {
	display: none;
}

.footer-logo{
	width: 200px !important;
}

.decidim-logo{
	margin-right: auto;
	margin-left: auto;
}


//FIX CONTRAST

.phase-current {
    font-weight: normal;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $par-dark-grey;
}

.phase-date {
    color: #726a70;
    font-weight: 600;
    font-size: 90%;
    color: $par-dark-grey;
}

.definition-data__title {
    display: block;
    font-size: 85%;
    font-weight: bold;
    letter-spacing: .01em;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: .25rem;
    color: $par-dark-grey;
}

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small {
    line-height: 0;
    color: $par-dark-grey;
}

.hero-heading {
    margin-bottom: 1.5rem;
    line-height: 1;
    text-shadow: 4px 4px 4px black;
}

.card__support__data--left {
    text-align: left;
    text-transform: none;
    color: black;
}

.timeline__date {
    color: $par-dark-grey;
}

.timeline__item--inactive .timeline__phase {
    background: $par-dark-grey;
}

.footer-nav{
    text-align: center;
    list-style-type: none;
}

.filters__section .section-title, .filters__section--general .section-title {
    color: black;
    text-decoration: underline;
    text-transform: capitalize;
    margin-right: 1rem;
}

.filters__section--general.no-results > * {
    opacity: .60;
    text-transform: capitalize;
}

a.muted, abbr.muted, acronym.muted, b.muted, bdo.muted, big.muted, br.muted, button.muted, cite.muted, code.muted, dfn.muted, em.muted, i.muted, img.muted, input.muted, kbd.muted, label.muted, map.muted, object.muted, q.muted, samp.muted, script.muted, select.muted, .inline-filters button.muted[data-toggle], small.muted, span.muted, strong.muted, sub.muted, sup.muted, textarea.muted, time.muted, tt.muted, var.muted {
    color: $par-dark-grey;
}

.process-header__hashtag a {
    text-shadow: none;
    background-color: #003183;
    border-radius: 5px;
    color: #fff;
    padding: 0 0.4em 0.1em;
    font-size: 1em; //mod_accessibility id 7 - Generale
}

//SOCIAL LOGIN
.social-login-button{
  display:flex;
  justify-content:center;
  align-items:center;
}

.spid-button-container{
  height:auto;
}

.spid-button{
  display:block;
  margin-left:auto;
  margin-right:auto;
}

.spid-button-large-idp-list{
  margin-left:18px;
}

.spid-button-mini-idp-list{
  margin-left:10px;
}

.spid-idp-label{
  box-sizing: initial;
}

.button--l .button--pua {
  background-color: #06c;
  border-color: #06c;
  font-weight: 600;
  cursor: pointer;
  padding: 12px;
  text-align: center;
  display: block;
}

//ASSEMBLIES
.filters__section.scope_id_scopes_picker_filter {
  display: none;
}

#participatory-space-filters .filters__section, #participatory-space-filters .filters__section--general {
  display: none;
}

#assemblies-chart {
  display: none;
}

// colore delle label che avvertono che è presente un errore
.is-invalid-label {
  color: $par-darkorange; // colore delle label che avvertono che è presente un errore mod_accessibility
}

// colore scritte di errore nei form
.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: $par-darkorange; // colore delle label che avvertono che è presente un errore mod_accessibility
}

// colore del bordo dei campi contrassegnati con errore
.is-invalid-input:not(:focus) {
  border-color: $par-darkorange; // colore delle label che avvertono che è presente un errore mod_accessibility
  background-color: #fdeeec;
}

// pagina Processi filtro aumentata saturazione di colore
.order-by__tab {
  color: #404040;
}

// Pagina risultati ricerca filtro attivo

.order-by__tab.is-active {
  color: $par-darkgreen !important;
  text-decoration-color: $par-darkgreen !important;
}

.filters__section--general.no-results .section-title {
  color: #404040;
  text-decoration: none;
}
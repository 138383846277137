// Copyright (C) 2023 Formez PA
// This program is free software: you can redistribute it and/or modify it under the terms of the GNU Affero General Public License as published by the Free Software Foundation, version 3.
// This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Affero General Public License for more details.
// You should have received a copy of the GNU Affero General Public License along with this program. If not, see <https://www.gnu.org/licenses/>

@mixin button-pua {
  &:only-child {
    margin-bottom: 100px;
  }
  .button--pua {
    background-color: #06c;
    border-color: #06c;
    font-weight: 600;
    cursor: pointer;
    padding: 12px;
    text-align: center;

    .button--social__text {
      margin-left: 0;
      text-align: center;
    }

    &:hover, &:active, &:focus {
      color: #fff;
      background-color: #0053a6;
      border-color: #004d99;
    }
  }
}

.button--s {
  @include button-pua;
  button {
    margin: 0 auto;
    width: 50%;
    min-width: 0;
  }
}

.button--m {
  @include button-pua;
  button {
    margin: 0 auto;
    width: 65%;
    min-width: 0;
  }
}

.button--l {
  @include button-pua;
  button {
    margin: 0 auto;
    width: 80%;
    min-width: 0;
  }
}

.button--xl {
  @include button-pua;
  button {
    margin: 0 auto;
    width: 100%;
  }
}
// Variables

$par-medium-green:		#297a4d;
$par-medium-green-rgb:  60,179,113;
$par-white: 				#fff;
$par-white-rgb:				255,255,255;
$par-light-blue:			#0059B3;
$par-light-blue-rgb:		0,89,179;
$par-blue:					#003183;
$par-blue-rgb:				0,49,131;
$par-dark-grey:				#0D0D0D;
$par-dark-grey-rgb:			96,96,96;
$par-black:					#000000;
$par-black-rgb:				0,0,0;
$par-darkorange: 			#a51d2d;
$par-darkgreen:             #006400;
$light-gray: #eee;
$medium-gray: #e8e8e8;
$light-gray-dark: darken($light-gray, 2.5);
$global-radius: 4px;
$border-width: 1px !default;
$border: $border-width solid $medium-gray !default;
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);
$body-font-color: #3d393c;

$primary: $par-medium-green;
$primary-rgb: $par-medium-green-rgb;
$secondary: $par-medium-green;
$secondary-rgb: $par-medium-green-rgb;
$success: #57d685 !default;
$success-rgb: 87,214,133 !default;
$warning: #ffae00 !default;
$warning-rgb: 255,174,0 !default;
$alert: #ec5840 !default;
$alert-rgb: 236,88,64 !default;

$highlight: #be6400 !default;
$highlight-rgb: 190,100,0 !default;
$highlight-alternative: #ff5731 !default;
$highlight-alternative-rgb: 255,87,49 !default;

$proposals: #238ff7 !default;
$actions: #57d685 !default;
$debates: #fa6c96 !default;
$meetings: #fabc6c !default;

$twitter: #55acee !default;
$facebook: #3b5998 !default;
$google: #dd4b39 !default;

// background colors used in public diff views
$color-addition: #e6ffed !default;
$color-removal: #ffeef0 !default;

// Define custom properties as css color variables

:root{
  --primary: #{$primary};
  --primary-rgb: #{$primary-rgb};
  --secondary: #{$secondary};
  --secondary-rgb: #{$secondary-rgb};
  --success: #{$success};
  --success-rgb: #{$success-rgb};
  --warning: #{$warning};
  --warning-rgb: #{$warning-rgb};
  --alert: #{$alert};
  --alert-rgb: #{$alert-rgb};
  --highlight: #{$highlight};
  --highlight-rgb: #{$highlight-rgb};
  --highlight-alternative: #{$highlight-alternative};
  --highlight-alternative-rgb: #{$highlight-alternative-rgb};
  --proposals: #{$proposals};
  --actions: #{$actions};
  --debates: #{$debates};
  --meetings: #{$meetings};
  --twitter: #{$twitter};
  --facebook: #{$facebook};
  --google: #{$google};
}

